@tailwind base;
@tailwind components;
@tailwind utilities;

input:-webkit-autofill::first-line {
    font-size: 14px !important;
}
input:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #999;
}
.css-n5f8r7 {
    display: flex;
    flex-wrap: wrap;
    margin: 4px -2px 0px 0px;
}
.css-81c5cm:nth-of-type(1) {
    flex-basis: 100%;
}
.css-13s3r1o {
    margin-right: 4px;
    width: 16px;
    height: 16px;
}
.css-1hkjabm {
    padding-top: 6px;
    color: rgb(104, 121, 146);
    font-size: 12px;
}
.css-81c5cm {
    text-align: center;
    margin: 0px 4px 4px 0px;
    flex: 1 1 0%;
    background: rgb(247, 247, 247);
    box-shadow: rgb(195, 205, 221) 0px 1px 2px;
    border-radius: 4px;
    color: rgb(52, 183, 241);
    font-weight: 600;
    font-size: 14px;
    padding: 12px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}
.css-1bofdss::before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-style: solid;
    border-width: 0px 8px 8px 0px;
    top: 0px;
    border-color: transparent rgb(255, 255, 255) transparent transparent;
    left: -8px;
}
.css-1bofdss {
    position: relative;
    color: rgb(36, 55, 78);
    border-radius: 0px 8px 8px;
    padding: 12px;
    font-size: 14px;
    word-break: break-word;
    background: rgb(255, 255, 255);
    box-shadow: rgb(195, 205, 221) 0px 1px 2px;
}
.css-1n3x2a2 {
    text-align: end;
    font-size: 12px;
    color: rgb(104, 121, 146);
}
.css-1xjjb7d {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
    margin-right: 12px;
    padding: 12px 8px;
    background-color: rgb(255, 255, 255);
    border-radius: 4px 0px 4px 4px;
    font-size: 14px;
    color: rgb(104, 121, 146);
}
.css-1xjjb7d::before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-style: solid;
    border-width: 0px 8px 8px 0px;
    top: 0px;
    border-color: transparent rgb(255, 255, 255) transparent transparent;
    right: -8px;
    transform: rotateY(180deg);
}
.css-19ylc6g {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: rgb(1, 137, 123);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}
.apexcharts-menu {
    color: black;
}
.apexcharts-tooltip-title {
    color: black;
}
.apexcharts-tooltip-text-y-label {
    color: black;
}
.apexcharts-tooltip-text-y-value {
    color: black;
}
.imIIYU {
    display: inline-flex;
    overflow: hidden;
    user-select: none;
    width: 100%;
    --webkit-user-select: none;
    position: relative;
}
.dLlWlu {
    position: relative;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    width: 100%;
    margin: 0px 3px 3px 0px;
}
.dQUuuB.right {
    height: calc(100% - 3px);
    width: 3px;
    transform: translate3d(0px, 0px, 0px) skewY(45deg);
    right: 0px;
    background-color: rgb(224, 224, 224);
    top: 0px;
}
.dQUuuB.bottom {
    width: calc(100% - 3px);
    height: 3px;
    transform: translate3d(0px, 0px, 0px) skewX(45deg);
    bottom: 0px;
    left: 0px;
    background-color: rgb(138, 138, 138);
}
.dQUuuB {
    position: absolute;
    transform-origin: 0% 0%;
    transition: transform 0.03s ease 0s;
}
.dvYBoD {
    outline: none;
    margin: 0px;
    border: 0px;
    background: transparent;
    padding: 0px;
    display: inline-flex;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    --webkit-user-select: none;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    will-change: transform;
}
.dvYBoD .button-face.elevated {
    margin: 0px 3px 3px 0px;
}
.dvYBoD .button-face {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    background-color: rgb(255, 255, 255);
    color: black;
    border: none;
    padding: 0px 30px;
    z-index: 2;
    height: 45px;
    width: 100%;
    transition: transform 0.12s ease-in-out 0s;
}
.dvYBoD:hover .button-face {
    background-color: var(--elevate-button-color);
    color: white !important;
}

.dvYBoD:hover .ethzMW {
    color: white !important;
}
.ethzMW {
    font-size: 14px;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: rgb(13, 13, 13);
}
.dvYBoD .button-edge.top {
    width: calc(100% - 3px);
    height: 3px;
    transform: skewX(45deg) scaleY(1);
    top: 0px;
    left: 0px;
    background-color: transparent;
}

.dvYBoD .button-edge {
    position: absolute;
    transform-origin: 0% 0%;
    transition: transform 0.12s ease-in-out 0s;
}
.dvYBoD .button-edge.left {
    height: calc(100% - 3px);
    width: 3px;
    transform: skewY(45deg) scaleY(1);
    left: 0px;
    top: 0px;
    z-index: 1;
    background-color: transparent;
}
.dvYBoD .button-edge.bottom {
    width: calc(100% - 3px);
    height: 3px;
    transform: translate3d(0px, 0px, 0px) skewX(45deg);
    bottom: 0px;
    left: 0px;
    background-color: rgb(138, 138, 138);
    z-index: 2;
}
.dvYBoD .button-edge.right {
    height: calc(100% - 3px);
    width: 3px;
    transform: translate3d(0px, 0px, 0px) skewY(45deg);
    right: 0px;
    background-color: rgb(224, 224, 224);
    top: 0px;
    z-index: 2;
}
.dvYBoD:active .button-face,
.dvYBoD.active .button-face {
    transform: translate3d(4px, 4px, 0px);
}
.dvYBoD:active .button-edge.bottom,
.dvYBoD.active .button-edge.bottom {
    transform: translate3d(4px, 4px, 0px) skewX(45deg);
}
.dvYBoD:active .button-edge.right,
.dvYBoD.active .button-edge.right {
    transform: translate3d(4px, 4px, 0px) skewY(45deg);
}

.mark {
    width: 15px;
    height: 15px;
    border: 1px solid rgb(255, 255, 255);
    box-sizing: border-box;
    position: relative;
    transition: transform 0.15s ease 0s;
}
.mark::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 8px;
    height: 8px;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: opacity 0.15s ease 0s;
    background-color: rgb(255, 255, 255);
}
.center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.actualScreenHeight {
    /* height: calc(100vh - 56px); */
    height: 100svh;
    /* min-height: -webkit-fill-available; */
}

#main-background {
    height: -webkit-fill-available;
}

.gradient-border {
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, rgb(139, 0, 60), rgb(50, 78, 204)) 1;
    border-radius: 5px;
}

.hideIn {
    display: none;
    animation: slideIn 1s ease-in-out forwards;
}
.hideOut {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    animation: slideOut 1s ease-in-out forwards;
}

.hideOut-normal {
    animation: slideOut 1s ease-in-out forwards;
}

@keyframes slideIn {
    0% {
        opacity: 1;
    }
    80% {
        opacity: 0.1;
    }
    100% {
        opacity: 0;
        display: none;
    }
}
@keyframes slideOut {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0.9;
    }
    100% {
        opacity: 1;
    }
}

* {
    font-family: "Poppins", sans-serif;
}
/* .nunito_font {
  font-family: "Nunito", sans-serif;
} */

.h-dashboard {
    height: 90vh;
}

.nice-back {
    background-image: url("https://www.planetware.com/wpimages/2020/02/france-in-pictures-beautiful-places-to-photograph-eiffel-tower.jpg");
}

.poppins {
    font-family: "Poppins", sans-serif;
}

.hide-scroll {
    scrollbar-width: none;
}
.hide-scroll::-webkit-scrollbar {
    display: none;
}
input[type="radio"] {
    appearance: none;
    width: 16px;
    height: 16px;
    border: 3.5px solid #eeeeee38;
    border-radius: 50%;
    background-clip: content-box;
}

input[type="radio"]:checked {
    background-color: #ff0040;
}
.customRadio:checked {
    background-color: #ff005c !important;
}

input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    width: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
}
.slider-track {
    width: 100%;
    height: 1px;
    background-color: #d5d5d5;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    border-radius: 5px;
}

input[type="range"]::-webkit-slider-runnable-track {
    appearance: none;
    -webkit-appearance: none;
    height: 5px;
}
input[type="range"]::-moz-range-track {
    appearance: none;
    -moz-appearance: none;
    height: 5px;
}
input[type="range"]::-ms-track {
    appearance: none;
    -moz-appearance: none;
    height: 5px;
}

input[type="range"]::-webkit-slider-thumb {
    margin-top: -5px;
    pointer-events: auto;
}
/* .tableplans {
    background-color: #222;
    box-shadow: inset 0px 2px 4px rgba(0, 255, 255, 0.3), inset 0px 4px 8px rgba(0, 255, 255, 0.3), inset 0px 8px 16px rgba(0, 255, 255, 0.3);
    transition: 0.2s;
    transform: translateY(2px);
} */

.tbody > tr > td {
    transition-duration: 200ms;
}
.tbody > tr:hover {
    background-color: #2d3037;
}
.tbody > tr > td {
    transition-duration: 200ms;
}
.tbody > tr > td:nth-child(1) {
    width: 25%;
    /* position: sticky;
  left: -20px; */
}
.tbody > tr > td:nth-child(2) {
    width: 15%;
}
.tbody > tr > td:nth-child(3) {
    width: 20%;
}
.tbody > tr > td:nth-child(4) {
    width: 20%;
}
.tbody > tr > td:nth-child(5) {
    width: 20%;
}

.table-row-spacer::before {
    content: "";
    display: table-row;
    height: 0.5rem; /* Adjust the height as needed */
}
