.smHeader {
    display: block;
    position: relative;
    text-transform: uppercase;
    font-size: 13px;
    color: #888888;
    margin: 30px 15px 0 15px;
}
.smHeader:after {
    position: absolute;
    top: 0.68rem;
    left: 0;
    content: "";
    height: 1px;
    background-color: #d3d3d3;
    width: 100%;
}
