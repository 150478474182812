@media only screen and (min-width: 992px) {
    .order-wizard-container {
        font-size: 1.4rem !important;
    }
}

.order-wizard-container {
    font-size: 1rem;
    width: 100%;
    max-width: 38rem;
    margin: 2rem auto 4rem;
}
.order-wizard-container .order-wizard-number,
.order-wizard-container .order-wizard-text {
    display: flex;
    align-items: center;
    justify-content: center;
}
.order-wizard-container .order-wizard-number .order-wizard-item.active {
    color: #f92147;
    border-color: #f92147;
}
.order-wizard-container .order-wizard-number .order-wizard-item.before {
    color: #fff;
    background-color: #f92147;
    border-color: #f92147;
}
.order-wizard-container .order-wizard-number .order-wizard-item {
    color: #6b7280;
    line-height: 1;
    display: flex;
    align-items: center;
    flex: 0 0 2.5rem;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border: thin solid #6b7280;
    border-radius: 99px;
}
.order-wizard-container .order-wizard-number .order-wizard-divide {
    flex-grow: 1;
    border: 0 none;
    border-top: thin solid #6b7280;
}
.order-wizard-container .order-wizard-text {
    font-size: 0.9em;
    justify-content: space-between !important;
}

.order-wizard-container .order-wizard-number,
.order-wizard-container .order-wizard-text {
    display: flex;
    align-items: center;
    justify-content: center;
}
.order-wizard-container .order-wizard-text .order-wizard-item:first-child {
    left: -1%;
}

.order-wizard-container .order-wizard-text .order-wizard-item.before,
.order-wizard-container .order-wizard-text .order-wizard-item.active {
    color: #f92147 !important;
}
.order-wizard-container .order-wizard-text .order-wizard-item {
    color: #6b7280;
    position: relative;
}
.order-wizard-container .order-wizard-text .order-wizard-item:nth-child(2) {
    left: 5%;
}

.order-wizard-container .order-wizard-text .order-wizard-item {
    color: #6b7280;
    position: relative;
}
.order-wizard-container .order-wizard-text .order-wizard-item:last-child {
    left: 6%;
}
.order-wizard-container .order-wizard-number .order-wizard-divide.active {
    border-top-color: #f92147;
}
